import { debounce } from "lodash";
import { VAT } from "../config";

export function mapContactToSave(contact) {
  const realMapping = [
    "gendercode",
    "firstname",
    "lastname",
    "new_bevorzugteemail",
    //"new_bevorzugtestelefon",
    "emailaddress2",
    "emailaddress3",
    "telephone1",
    "telephone2",
    "telephone3",
    "mobilephone",
    "mobilephone",
    "birthdate",
    "new_bevorzugtepostadresse",
    "address2_line1",
    "address2_postalcode",
    "address2_city",
    "address3_line1",
    "address3_postalcode",
    "address3_city",
    "new_firmennameaccess",
    "efa_billingaddressdifferent",
    "efa_invoicecompany",
    "new_uid_nummer",
    "efa_invoiceemail",
    "efa_invoicestreet",
    "efa_invoicepostalcode",
    "efa_invoicecity",
    "efa_emergencycontact",
    // "donotbulkemail",
    "new_agbakzeptiert",
    "new_datenschutzok",
    "efa_scholarship_terms_accepted",
    "efa_codeofconductaccepted",
    "efa_otherfieldofactivity",
    "efa_otherposition",
    "efa_otherindustry",
    "new_firmennameaccess",
    "efa_trackinterest",
    "efa_attendancedatatransfer",
    "efa_pronouns",
    "efa_vatnumber_business",
    "efa_mentionpronouns",
    "efa_otherpronouns",
  ];
  const relationMapping = [
    {
      field: "_navax_language_value",
      key: "navax_Language@odata.bind",
      valuePrefix: "navax_languages",
    },
    {
      field: "_new_positionefa_value",
      key: "new_PositionEFA@odata.bind",
      valuePrefix: "new_positionefas",
    },
    {
      field: "_new_branche_value",
      key: "new_Branche@odata.bind",
      valuePrefix: "navax_industries",
    },
    {
      field: "_new_landgeschftlich_value",
      key: "new_Landgeschftlich@odata.bind",
      valuePrefix: "navax_countries",
    },
    {
      field: "_new_landprivat_value",
      key: "new_Landprivat@odata.bind",
      valuePrefix: "navax_countries",
    },
    {
      field: "_efa_invoicecountry_value",
      key: "efa_invoicecountry@odata.bind",
      valuePrefix: "navax_countries",
    },
    {
      field: "_new_nationalitt_value",
      key: "new_Nationalitt@odata.bind",
      valuePrefix: "navax_countries",
    },
    {
      field: "_efa_fieldofactivity_value",
      key: "efa_fieldofactivity@odata.bind",
      valuePrefix: "efa_fieldofactivities",
    },
  ];

  let object = {};
  realMapping.forEach((key) => (object[key] = contact[key]));
  relationMapping.forEach((mapping) => {
    if (contact[mapping.field]) {
      object[mapping.key] =
        mapping.valuePrefix + "(" + contact[mapping.field] + ")";
    }
  });
  // do negation for bulkmail
  object.donotbulkemail = !contact["bulkemail"];
  return object;
}

export function billingAdress(contact) {
  let address = null;
  if (contact.efa_billingaddressdifferent) {
    address = {
      company: contact.efa_invoicecompany,
      street: contact.efa_invoicestreet,
      zip: contact.efa_invoicepostalcode,
      city: contact.efa_invoicecity,
      country:
        contact[
          "_efa_invoicecountry_value@OData.Community.Display.V1.FormattedValue"
        ],
      uid: contact.new_uid_nummer,
    };
  } else if (contact.new_bevorzugtepostadresse == 100000002) {
    address = {
      company: null,
      street: contact.address3_line1,
      zip: contact.address3_postalcode,
      city: contact.address3_city,
      country:
        contact[
          "_new_landprivat_value@OData.Community.Display.V1.FormattedValue"
        ],
      uid: null,
    };
  } else {
    address = {
      company: contact.new_firmennameaccess, //TODO passt das so?
      street: contact.address2_line1,
      zip: contact.address2_postalcode,
      city: contact.address2_city,
      country:
        contact[
          "_new_landgeschftlich_value@OData.Community.Display.V1.FormattedValue"
        ],
      uid: contact.new_uid_nummer,
    };
  }

  return {
    name: contact.firstname + " " + contact.lastname,
    mail:
      contact.new_bevorzugteemail == 100000000
        ? contact.emailaddress3
        : contact.emailaddress2,
    address,
  };
}

// We need to globaly debounce reportValidity, since we can only have one
// report at a time. Otherwise we might get into an infinite loop.
export const reportValidity = debounce(
  (target) => target?.reportValidity(),
  100,
  {
    trailing: true,
    leading: false,
  }
);

export function uuid() {
  if ("randomUUID" in self.crypto) {
    return self.crypto.randomUUID();
  }

  // https://stackoverflow.com/a/2117523/11383840
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (self.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function useMock() {
  const params = new URLSearchParams(window.location.search);
  return params.has("mock");
}

export function withVAT(value) {
  const price = value * (VAT + 1);
  return price.toFixed(2);
}

export const utils = {
  install: (app) => {
    app.config.globalProperties.$currency = (value, userOptions = {}) => {
      const options = { style: "currency", currency: "EUR", ...userOptions };
      return new Intl.NumberFormat("en", options).format(value);
    };
  },
};

export function linkedInInsights(consent) {
  const id = "linkedin-script";
  const currentScript = document.getElementById(id);

  if (!consent) {
    currentScript?.remove();
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("id", id);
  script.setAttribute("type", "text/javascript");
  script.innerText =
    '(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);';
  document.body.append(script);
}
