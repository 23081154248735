import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Registered from "../views/Registered.vue";
import Redeemed from "../views/TokenRedeemed.vue";
import Contact from "../views/Contact.vue";
import Contactlist from "../views/Contactlist.vue";
import ConfirmMail from "../views/ConfirmMail.vue";
import Tickets from "../views/Tickets.vue";
import CheckoutSurvey from "../views/CheckoutSurvey.vue";
import CheckoutApproval from "../views/CheckoutApproval.vue";
import CheckoutPayment from "../views/CheckoutPayment.vue";
import CheckoutComplete from "../views/CheckoutComplete.vue";
import Basket from "../views/Basket.vue";
import Invitations from "../views/Invitations.vue";

//TODO guards
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "ticket/:token/",
        name: "direktticket",
        beforeEnter: async (to) => {
          await store.dispatch("validateLink", {
            ticketPermit: to.params.token,
          });
        },
      },
      {
        path: "token/:token/:jwt",
        beforeEnter: async (to) => {
          await store.dispatch("validateLink", {
            ticketPermit: to.params.token,
            jwtToken: to.params.jwt,
          });
        },
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return (
        !store.getters.isPersonalizedTicket && !store.getters.isRedeemedTicket
      );
    },
  },
  {
    path: "/registered",
    name: "Registered",
    component: Registered,
  },
  {
    path: "/redeemed",
    name: "Redeemed",
    component: Redeemed,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return (
        !store.getters.isPersonalizedTicket && store.getters.isRedeemedTicket
      );
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return !!store.state.contact;
    },
  },
  {
    path: "/contactlist",
    name: "Contactlist",
    component: Contactlist,
  },
  {
    path: "/confirmmail",
    name: "ConfirmMail",
    component: ConfirmMail,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return store.getters.isPersonalizedTicket && !store.state.contact;
    },
  },
  {
    path: "/confirmmail/invitation",
    name: "ConfirmMailInvitation",
    component: ConfirmMail,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return store.getters.isPersonalizedTicket && !store.state.contact;
    },
  },
  {
    path: "/checkout/survey",
    name: "CheckoutSurvey",
    component: CheckoutSurvey,
  },
  {
    path: "/checkout/approval",
    name: "CheckoutApproval",
    component: CheckoutApproval,
  },
  {
    path: "/checkout/payment",
    name: "CheckoutPayment",
    component: CheckoutPayment,
  },
  {
    path: "/checkout/complete",
    name: "CheckoutComplete",
    component: CheckoutComplete,
  },
  {
    path: "/basket",
    name: "Basket",
    component: Basket,
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: Tickets,
    beforeEnter: () => {
      if (process.env.NODE_ENV === "development") {
        return true;
      }

      return store.getters.hasDetailedTicketInfos;
    },
  },
  {
    path: "/invitations",
    name: "Invitations",
    component: Invitations,
  },
  {
    path: "/*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHashHistory(window.location.pathname),
  routes,
  scrollBehavior() {
    return {
      el: "#app",
      top: 150,
    };
  },
});

router.beforeEach((to) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }

  if (to.path.startsWith("/ticket/") || to.path.startsWith("/token/")) {
    return;
  }

  if (!store.state.ticketPermit && to.path != "/") {
    return "/";
  }
});

router.afterEach((to) => {
  window.gtag?.("event", "page_view", {
    page_title: `Ticketshop - ${to.path}`,
    page_location: to.path,
  });
});

export default router;
