<template>
  <div class="cv-ticket-permit-info">
    <div class="cv-ticket-permit-info__icon">
      <span class="bh-font-ticket"></span>
    </div>
    <div class="cv-ticket-permit-info__name">{{ ticket.ticketName }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["ticket"]),
  },
};
</script>

<style lang="scss">
.cv-ticket-permit-info {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid black;
  overflow: hidden;
  padding: 1rem;
}
.cv-ticket-permit-info__icon {
  padding: unit(1);
  padding-right: 0;
  color: $color-gold;
  font-size: unit(1.5);
  color: $color-headings-dark;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  font-weight: 700;
  font-family: "degular";

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 32px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
.cv-ticket-permit-info__name {
  padding: unit(1);
  color: $color-headings-dark;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  font-weight: 700;
  font-family: "degular";

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 32px;
  }
}
</style>
