<template>
  <div class="cv-ticketshop">
    <template v-if="!error">
      <cv-step-navigation />
      <main class="cv-ticketshop-body">
        <cv-loading v-if="$store.state.loading" />
        <router-view v-else />
      </main>
    </template>
    <template v-else>
      <div class="cv-ticketshop__error cv-ticketshop-body">
        <div class="cv-text--intro cv-margin">
          <div class="cv-style--error">
            {{ $t("error_headline") }}
          </div>
          <div>{{ error }}</div>
        </div>
        <button class="cv-button" @click="confirmError">{{ $t("error_button") }}</button>
      </div>
    </template>
  </div>
</template>

<script>
import CvLoading from "./components/Loading.vue";
import CvStepNavigation from "./components/StepNavigation.vue";

export default {
  components: {
    CvLoading,
    CvStepNavigation,
  },
  computed: {
    token() {
      return this.$store.getters.decodedToken;
    },
    error() {
      return this.$store.state.error;
    },
  },
  methods: {
    confirmError() {
      this.$store.dispatch("error", null);
    },
  },
};
</script>

<style lang="scss">
.cv-ticketshop {
  margin-top: 4rem;
  background: $color-white;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Mundo Sans Std", sans-serif;
}

.cv-ticketshop-body {
  padding: unit(2);

  @media (max-width: 768px) {
    padding: 15px;
  }
}
</style>
