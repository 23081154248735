<template>
  <div v-if="subtickets.length" class="cv-subtickets">
    <div class="cv-subtickets__label">{{ $t("subtickets_label") }}</div>
    <ul class="cv-subtickets__list">
      <li
        v-for="subticket in subtickets"
        :key="subticket.efa_ticketcategoryid"
        class="cv-subticket"
      >
        <div class="cv-subticket__icon bh-font-check"></div>
        <div class="cv-subticket__name">{{ subticket.efa_name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    ticket: Object,
  },
  computed: {
    subtickets() {
      return this.ticket.efa_ticketcategory
        .efa_ticketcategory_efa_ticketcategory_efa;
    },
  },
};
</script>

<style lang="scss">
.cv-subtickets {
  @extend %text-infobox;
}
.cv-subtickets__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cv-subticket {
  display: flex;
  align-items: center;
}
.cv-subticket__icon {
  margin-right: unit(0.5);
}
</style>
