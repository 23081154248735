/**
 * 1 = aktiv (selectable)
 * 2 = inaktiv
 * 221120000 = in Genehmigung (EFA muss bestätigen)
 * 221120001 = genehmigt (EFA hat bestätigt) (selectable)
 * 221120002 = Umfrage ausständig (Kunde muss Umfrage ausfüllen)
 * 221120003 = Abgelehnt (EFA hat abgelehnt)
 * 221120004 = Storniert (Kunde hat storniert und EFA akzeptiert)
 * 221120005 = in Bezahlung
 * 221120006 = Bezahlt
 */

const sorting = [
  221120006,
  221120005,
  221120000,
  221120002,
  221120001,
  1,
  221120003,
  221120004,
  2,
];

const activeStates = [1, 221120001];

const inactiveStates = [2, 221120004, 221120003];

const pendingStates = [221120000, 221120002, 221120005];

const completedStates = [221120006];

export function isStateActive(item) {
  return activeStates.includes(item.statuscode);
}

export function isStatePending(item) {
  return pendingStates.includes(item.statuscode);
}

export function isStateInactive(item) {
  return inactiveStates.includes(item.statuscode);
}

export function isStateCompleted(item) {
  return completedStates.includes(item.statuscode);
}

export function isSoldout(item) {
  return item.efa_ticketcategory.efa_soldout && !isStateCompleted(item);
}

export function isSelectable(item) {
  return isStateActive(item) && !isSoldout(item);
}

export function isBookable(item) {
  return (
    (!item.efa_requiresapproval || getApprovedState(item) == 1) &&
    (!item._efa_surveyid_value || hasSurveyCompleted(item)) &&
    isSelectable(item)
  );
}

/**
 * 0 = in bezahlung
 * 1 = bezahlt
 * null = noch keine aktion vorgenommen
 */
export function getBookableState(item) {
  if (item.statuscode == 221120005) {
    return 0;
  } else if (item.statuscode == 221120006) {
    return 1;
  } else {
    return null;
  }
}

export function approvalRequired(item) {
  return item.efa_requiresapproval;
}

/**
 * 0 = genehmigung ausständig
 * 1 = durch EFA genehmigt
 * -1 = durch EFA abgelehnt
 * null = noch keine aktion vorgenommen
 */
export function getApprovedState(item) {
  if ([221120006, 221120005, 221120004, 221120001].includes(item.statuscode)) {
    return 1;
  } else if (item.statuscode == 221120003) {
    return -1;
  } else if (item.statuscode == 221120000) {
    return 0;
  } else {
    return null;
  }
}

export function surveyRequired(item) {
  return !!item._efa_surveyid_value;
}

/**
 * 0 = umfrage ausständig
 * 1 = umfrage ausgefüllt
 * null = noch keine aktion vorgenommen
 */
export function getSurveyState(item) {
  if (hasSurveyCompleted(item)) {
    return 1;
  } else if (item.statuscode == 221120002) {
    return 0;
  } else {
    return null;
  }
}

export function hasSurveyCompleted(item) {
  return !!item.efa_surveystatus;
}

export function sortByState(a, b) {
  return sorting.indexOf(a.statuscode) - sorting.indexOf(b.statuscode);
}
