<template>
  <div class="cv-alpbach-button">
    <button @click="toAlpbach" type="submit" class="button-wrapper btn">
      <span>{{ $t("alpbach_home") }}</span>
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    currentLanguage() {
      return document.documentElement.lang;
    },
  },
  methods: {
    toAlpbach() {
      if (this.currentLanguage === "de") {
        window.top.location.href = "https://www.alpbach.org/de/";
      } else {
        window.top.location.href = "https://www.alpbach.org/";
      }
    },
  },
};
</script>

<style lang="scss">
.cv-alpbach-button {
  .button-wrapper {
    @extend %button;

    width: 100%;
    position: relative;
    background-color: black;
    color: white;
    border-radius: 2px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
      color: white;

      .input {
        background-color: black;
      }
    }

    &:disabled,
    &.disabled {
      color: $color-grey-500;
    }

    &::after {
      content: "";
      background-image: url("../assets/sass/ic_arrow_right.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      height: 24px;
      width: 40px;
      display: block;
      margin-left: 16px;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      @media (min-width: 768px) {
        width: 24px;
      }
    }

    &:hover {
      &::after {
        margin-left: 22px;
      }
    }

    span {
      font-family: "degular";
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    input {
      position: absolute;
      inset: 0;
      background-color: transparent;
      font-size: 0;
      border: 0;
      cursor: pointer;
    }
  }
}
</style>
