<template>
  <div class="cv-registered">
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--success">
        {{ $t("registration_submitted") }}
      </h2>
      <p :style="{ marginBottom: 0 }">
        {{ $t("registration_mail_will_be_transmitted") }}
      </p>
    </div>
    <cv-alpbach-button></cv-alpbach-button>
  </div>
</template>

<script>
import CvAlpbachButton from "../components/AlpbachButton.vue";
export default {
  components: {
    CvAlpbachButton,
  },
};
</script>

<style lang="scss">
.cv-registered .cv-text--intro {
  display: flex;
  flex-direction: column;

  .cv-style--success {
    font-family: "degular";
    font-weight: 700;
    color: $color-headings-dark;
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 40px;

    @media (min-width: 768px) {
      font-size: 58px;
      line-height: 58px;
    }
  }

  .cv-button {
    background-color: black;
    color: white;
    border-radius: 2px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 18px;
    line-height: 23.89px;
    display: flex;
    font-family: "degular";
    font-weight: 600;

    &:hover,
    &:focus {
      color: white;

      .input {
        background-color: black;
      }
    }

    &:disabled,
    &.disabled {
      color: $color-grey-500;
    }

    &::after {
      content: "";
      background-image: url("../assets/sass/ic_arrow_right.svg");
      background-repeat: no-repeat;
      background-size: 24px;
      height: 24px;
      width: 40px;
      display: block;
      margin-left: 16px;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      @media (min-width: 768px) {
        width: 24px;
      }
    }

    &:hover {
      &::after {
        margin-left: 22px;
      }
    }
  }
}

.cv-registered .cv-button {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
