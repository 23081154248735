import { createLogger, createStore } from "vuex";

import metadata from "./modules/metadata";
import ticket from "./modules/ticket";
import router from "../router";
import api from "../api";
import { mapContactToSave, useMock } from "../utils";
import jwt_decode from "jwt-decode";
import { saveStorage, getStorage, clearStorage } from "../utils/localStorage";
import { VAT } from "../config";
import * as Sentry from "@sentry/vue";

const defaultState = {
  loading: false,
  step: 1,
  ticketPermit: null,
  tokenJWT: null,
  contact: null,
  contactlist: null,
  tickets: null,
  error: null,
  ticketLinks: null,
  firstSelectedTicket: null,
  registerMail: null,
  registerFirstName: null,
  registerLastName: null,
  firstSelectedTicketCodes: [],
  firstSelectedTickets: [],
  invitation: false,
  invitationSessions: [],
  sessionWaitlist: [],
};

const isSameTicketCode = function (a, b) {
  return a == b;
};

const plugins = [
  process.env.NODE_ENV !== "production" ||
  process.env.VUE_APP_STORE_LOGGER === "true"
    ? createLogger()
    : null,
].filter((plugin) => plugin !== null);

const getters = {
  isPersonalizedTicket: (state) => {
    return !state.ticketPermit.data.efa_istemplate;
  },
  isRedeemedTicket: (state) => {
    return state.ticketPermit.data["ticketpermitlink.efa_isredeemed"];
  },
  hasDetailedTicketInfos: (state) => {
    //TODO in ticket modul
    return state.ticketPermit && state.ticketPermit.detail;
  },
  ticket: (state) => {
    let data = state.ticketPermit.data;
    return {
      ticketName: data["efa_name"],
      eventName:
        data["_efa_eventid_value@OData.Community.Display.V1.FormattedValue"],
    };
  },
  decodedToken: (state) => {
    if (state.tokenJWT) {
      return jwt_decode(state.tokenJWT);
    } else {
      return null;
    }
  },
  ticketLinks: (state) => {
    return state.ticketLinks;
  },
  invitation: (state) => {
    return state.invitation;
  },
  firstSelectedTicket: (state) => {
    return state.firstSelectedTicket;
  },
  registerMail: (state) => {
    return state.registerMail;
  },
  registerFirstName: (state) => {
    return state.registerFirstName;
  },
  registerLastName: (state) => {
    return state.registerLastName;
  },
  selectedTicketCodes: (state) => {
    return state.firstSelectedTicketCodes;
  },
  selectedTickets: (state) => {
    return state.firstSelectedTickets;
  },
  isSelectedCode: (state) => (code) => {
    return !!state.firstSelectedTicketCodes.find((selectedCode) =>
      isSameTicketCode(selectedCode, code)
    );
  },
  isSelectedInvitation: () => (invitation) => {
    return invitation.statuscode == 221120003; // Statuscode akzeptiert
  },
  isRejectedInvitation: () => (invitation) => {
    return invitation.statuscode == 221120004; // Statuscode abgelehnt
  },
  invitationSessions: (state) => {
    return state.invitationSessions;
  },

  sessionWaitlist: (state) => {
    return state.sessionWaitlist ?? [];
  },

  isReserved: (state) => (invitation) => {
    const sessionWaitlist = state.sessionWaitlist.find((entry) => {
      return (
        entry["_efa_sessioninvitation_value"] ===
        invitation.efa_sessioninvitationid
      );
    });

    if (!sessionWaitlist) {
      return false;
    }

    return sessionWaitlist.efa_reserved === true;
  },

  waitlistPosition: (state) => (invitation) => {
    const sessionWaitlist = state.sessionWaitlist.find((entry) => {
      return (
        entry["_efa_sessioninvitation_value"] ===
        invitation.efa_sessioninvitationid
      );
    });

    if (!sessionWaitlist) {
      return -1;
    }

    return sessionWaitlist.position ?? -1;
  },

  waitlistInvitedOn: (state) => (invitation) => {
    const sessionWaitlist = state.sessionWaitlist.find((entry) => {
      return (
        entry["_efa_sessioninvitation_value"] ===
        invitation.efa_sessioninvitationid
      );
    });

    if (!sessionWaitlist) {
      return null;
    }

    return sessionWaitlist.efa_invitedon ?? null;
  },

  isJoining: (state) => (invitation) => {
    const sessionWaitlist = state.sessionWaitlist.find((entry) => {
      return (
        entry["_efa_sessioninvitation_value"] ===
        invitation.efa_sessioninvitationid
      );
    });

    if (!sessionWaitlist) {
      return false;
    }

    return sessionWaitlist.statuscode === 221120003;
  },
};

export default createStore({
  devtools: true,
  plugins,
  state: Object.assign({}, defaultState),
  mutations: {
    reset: (state) => {
      Object.assign(state, defaultState);
    },
    loading: (state, value) => {
      state.loading = value;
    },
    invitation: (state, value) => {
      state.invitation = value;
    },
    step: (state, value) => {
      state.step = value;
    },
    initTicketPermit: (state, data) => {
      state.ticketPermit = data;
    },
    tokenJWT: (state, value) => {
      state.tokenJWT = value;
      sessionStorage.setItem("jwt", value);

      try {
        const token = jwt_decode(value);
        Sentry.setUser({ email: token.email });
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    contact: (state, data) => {
      state.contact = data;
      state.contactlist = null;
    },
    contactlist: (state, data) => {
      state.contactlist = data;
    },
    openInvitations: (state, data) => {
      if (!state.openInvitations.includes(data)) {
        state.openInvitations.push(data);
      }
    },
    upcomingSessions: (state, data) => {
      if (!state.upcomingSessions.includes(data)) {
        state.upcomingSessions.push(data);
      }
    },
    error: (state, message) => {
      state.error = message;
    },
    ticketLinks: (state, data) => {
      state.ticketLinks = data;
    },
    firstSelectedTicket: (state, item) => {
      state.firstSelectedTicket = item;
    },
    registerMail: (state, data) => {
      state.registerMail = data;
    },
    registerFirstName: (state, data) => {
      state.registerFirstName = data;
    },
    registerLastName: (state, data) => {
      state.registerLastName = data;
    },
    selectCode: (state, code) => {
      state.firstSelectedTicketCodes.push(code);
    },
    selectTicket: (state, ticket) => {
      state.firstSelectedTickets.push(ticket);
    },
    unselectCode: (state, code) => {
      state.firstSelectedTicketCodes = state.firstSelectedTicketCodes.filter(
        (selectedCode) => !isSameTicketCode(selectedCode, code)
      );
    },
    unselectTicket: (state, ticket) => {
      state.firstSelectedTickets = state.firstSelectedTickets.filter(
        (selectedTicket) => !isSameTicketCode(selectedTicket, ticket)
      );
    },
    invitationSessions: (state, invitations) => {
      state.invitationSessions = [];
      invitations.forEach((invitation) => {
        state.invitationSessions.push(invitation);
      });
    },
    sessionWaitlist: (state, sessionWaitlist) => {
      state.sessionWaitlist = Array.isArray(sessionWaitlist)
        ? sessionWaitlist
        : [];
    },
  },
  getters: getters,
  actions: {
    init({ dispatch }) {
      dispatch("ticketLinks");
    },
    ticketLinks({ commit, dispatch }) {
      if (process.env.NODE_ENV === "development" && useMock()) {
        let links = [
          {
            "price@OData.Community.Display.V1.FormattedValue": "4.000,00 €",
            price: 4000,
            "validfrom@OData.Community.Display.V1.FormattedValue": "17.08.2024",
            validfrom: "2024-08-17T00:00:00Z",
            "validto@OData.Community.Display.V1.FormattedValue": "18.08.2024",
            validto: "2024-08-18T00:00:00Z",
            externalcode: "2024-HLB1-B0MA",
            "ticket@OData.Community.Display.V1.FormattedValue":
              "EFA24 - Pax - Participant",
            ticket: "e0ad66c6-f9e1-ee11-904c-7c1e52123c19",
          },
          {
            "price@OData.Community.Display.V1.FormattedValue": "0,00 €",
            price: 0,
            "validfrom@OData.Community.Display.V1.FormattedValue": "24.08.2024",
            validfrom: "2024-08-24T00:00:00Z",
            "validto@OData.Community.Display.V1.FormattedValue": "27.08.2024",
            validto: "2024-08-27T00:00:00Z",
            externalcode: "2024-YAHN-WES2",
            "ticket@OData.Community.Display.V1.FormattedValue":
              "EFA24 - Press - AiE - Journalist",
            ticket: "57c309fb-fbd5-4aed-bb01-fb24d66ee608",
          },
          {
            "price@OData.Community.Display.V1.FormattedValue": "0,00 €",
            price: 0,
            "validfrom@OData.Community.Display.V1.FormattedValue": "31.08.2024",
            validfrom: "2024-08-31T00:00:00Z",
            "validto@OData.Community.Display.V1.FormattedValue": "02.09.2024",
            validto: "2024-09-02T00:00:00Z",
            externalcode: "2024-YAHN-WES2",
            "ticket@OData.Community.Display.V1.FormattedValue":
              "EFA24 - Press - AiE - Journalist",
            ticket: "57c309fb-fbd5-4aed-bb01-fb24d66ee608",
          },
          {
            "price@OData.Community.Display.V1.FormattedValue": "0,00 €",
            price: 0,
            "validfrom@OData.Community.Display.V1.FormattedValue": "31.08.2024",
            validfrom: "2024-08-31T00:00:00Z",
            "validto@OData.Community.Display.V1.FormattedValue": "31.08.2024",
            validto: "2024-08-31T00:00:00Z",
            externalcode: "2024-YAHN-WES2",
            "ticket@OData.Community.Display.V1.FormattedValue":
              "EFA24 - Press - AiE - Journalist",
            ticket: "57c309fb-fbd5-4aed-bb01-fb24d66ee608",
          },
          {
            "price@OData.Community.Display.V1.FormattedValue": "0,00 €",
            price: 0,
            "validfrom@OData.Community.Display.V1.FormattedValue": "30.12.2024",
            validfrom: "2024-12-30T00:00:00Z",
            "validto@OData.Community.Display.V1.FormattedValue": "02.01.2025",
            validto: "2025-01-02T00:00:00Z",
            externalcode: "2024-YAHN-WES2",
            "ticket@OData.Community.Display.V1.FormattedValue":
              "EFA24 - Press - AiE - Journalist",
            ticket: "57c309fb-fbd5-4aed-bb01-fb24d66ee608",
          },
        ];

        commit("ticketLinks", links);
        return;
      }

      api
        .ticketLinks()
        .then(function (result) {
          commit("ticketLinks", result.value);
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Could not fetch Ticketlinks");
        });
    },

    sessionWaitlist({ state, commit, dispatch }) {
      api
        .sessionWaitlist(state.tokenJWT)
        .then(function (result) {
          commit("sessionWaitlist", result.value);
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Could not fetch Session Waitlist");
        });
    },
    selectTicketCode({ commit, getters, state }, { code, ticket }) {
      if (!getters.isSelectedCode(code)) {
        commit("selectCode", code);
        commit("selectTicket", ticket);

        const ticketData = state.ticketLinks.find((tl) => tl.ticket === ticket);

        if (ticketData) {
          window.gtag?.("event", "add_to_cart", {
            currency: "EUR",
            value: ticketData.price,
            tax: ticketData.price * VAT,
            items: [
              {
                item_id: ticketData.code,
                item_name:
                  ticketData[
                    "ticket@OData.Community.Display.V1.FormattedValue"
                  ],
                price: ticketData.price,
                quantity: 1,
              },
            ],
          });
        }
      }
    },
    unselectTicketCode({ commit, getters, state }, { code, ticket }) {
      if (getters.isSelectedCode(code)) {
        commit("unselectCode", code);
        commit("unselectTicket", ticket);

        const ticketData = state.ticketLinks.find((tl) => tl.ticket === ticket);

        if (ticketData) {
          window.gtag?.("event", "remove_from_cart", {
            currency: "EUR",
            value: ticketData.price,
            tax: ticketData.price * VAT,
            items: [
              {
                item_id: ticketData.code,
                item_name:
                  ticketData[
                    "ticket@OData.Community.Display.V1.FormattedValue"
                  ],
                price: ticketData.price,
                quantity: 1,
              },
            ],
          });
        }
      }
    },
    acceptInvitation({ state, dispatch, commit }, invitation) {
      api
        .updateSessionRegistration(
          invitation.efa_sessioninvitationid,
          221120003,
          state.tokenJWT
        )
        .then((data) => {
          commit("invitationSessions", data["value"]);
          dispatch("sessionWaitlist");
        })
        .catch(() => {
          dispatch(
            "error",
            "Token, id or statuscode invalid in Update Session Registration"
          );
        });
    },
    rejectInvitation({ state, dispatch, commit }, invitation) {
      api
        .updateSessionRegistration(
          invitation.efa_sessioninvitationid,
          221120004,
          state.tokenJWT
        )
        .then((data) => {
          commit("invitationSessions", data["value"]);
          dispatch("sessionWaitlist");
        })
        .catch(() => {
          dispatch(
            "error",
            "Token, id or statuscode invalid in Update Session Registration"
          );
        });
      // }
    },
    joinWaitlist({ state, dispatch, commit }, invitation) {
      api
        .updateSessionRegistration(
          invitation.efa_sessioninvitationid,
          221120005,
          state.tokenJWT
        )
        .then((data) => {
          commit("invitationSessions", data["value"]);
          dispatch("sessionWaitlist");
        })
        .catch(() => {
          dispatch(
            "error",
            "Token, id or statuscode invalid in Update Session Registration"
          );
        });

      // fetch sessionWaitlist
      // }
    },
    // Overwrites existing user data for the given key in local storage.
    saveTicketInLocalStorage({ commit }, { key, ticket }) {
      commit("firstSelectedTicket", ticket);
      saveStorage(key, ticket);
    },
    // Get ticket of local storage via key
    async getTicketOfLocalStorage({ commit }, key) {
      getStorage(key).then(function (result) {
        commit("firstSelectedTicket", result);
      });
    },
    // Get user data of local storage via key
    saveUserDataInLocalStorage({ commit }, { key, userData }) {
      commit(key, userData);
      saveStorage(key, userData);
    },
    clearLocalStorage() {
      clearStorage();
    },
    // eslint-disable-next-line no-empty-pattern
    clearLocalStorageWithKey({}, key) {
      clearStorage(key);
    },
    // Get first selected ticket of local storage via key
    async getUserDataOfLocalStorage({ commit }, key) {
      getStorage(key).then(function (result) {
        if (result) {
          commit(key, result);
        } else {
          return null;
        }
      });
    },
    /**
     * Das ist der initiale API Call, bei dem der Ticket Code aus dem Link/Url geprüft wird.
     * Fall 1: der Code ist gültig und personalisiert
     * Fall 2: der Code ist gültig und ein Template (anonymer Voucher)
     * Fall 3: das selbe wie Fall 2, aber der Code wurde schon eingelöst
     * Fall 4: der Code ist nicht gültig
     * Fall 5: der Code ist gültig, und anonym mit einem JWT Token (User kommt vom Bestätigungsmail)
     * @param {*} param0
     * @param {ticketPermit, jwtToken} ticketPermit Code aus dem Link/Url, jwtToken Token aus dem Bestätigungsmail Link/Url
     */
    validateLink({ commit, dispatch, getters }, { ticketPermit, jwtToken }) {
      commit("reset");

      if (/invitation=true/.test(window.location.href)) {
        commit("invitation", true);
      } else {
        commit("invitation", false);
      }

      dispatch("ticketLinks");
      commit("ticket/reset");
      commit("loading", true);

      return api
        .checkTicketCode(ticketPermit.replace("&invitation=true", ""))
        .then(async (data) => {
          commit("loading", false);
          commit("initTicketPermit", {
            code: ticketPermit,
            data,
          });

          if (data.efa_istemplate && data["ticketpermitlink.efa_isredeemed"]) {
            router.push("/redeemed");
          } else if (data.efa_istemplate && jwtToken) {
            commit("tokenJWT", jwtToken);
            await dispatch("veryfyContact");
          } else if (data.efa_istemplate) {
            commit("step", 2);
            router.push("/register");
          } else if (getters.invitation && jwtToken) {
            commit("tokenJWT", jwtToken);
            await dispatch("veryfyContact");
          } else if (getters.invitation) {
            commit("step", 2, { root: true });
            router.push("/confirmmail/invitation");
          } else {
            commit("step", 2, { root: true });
            router.push("/confirmmail");
          }
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Token invalid");
        });
    },
    /**
     * Wird aufgerufen wenn ein anonymes Ticket aufgerufen wird und die Eingabe Felder gesammelt wurden
     * Im weiteren verlauft wird ein Mail von der API an den User gesendet in dem der JWT enthalten ist
     * @param {*} param0
     * @param Object data {firstName, lastName, mail}
     */
    createUser({ commit, dispatch, state, getters }, data) {
      commit("loading", true);
      const ticketStrings = getters.selectedTickets.toString();
      const tickets = ticketStrings?.replaceAll(",", ";");
      api
        .createUser(
          state.ticketPermit.code,
          data.firstName,
          data.lastName,
          data.mail,
          tickets
        )
        .then(() => {
          //TODO check result
          commit("loading", false);
          router.push("/registered");
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Registration failed, please try again.");
        });
    },
    /**
     * Wenn das Ticket personalisiert ist, wird hier nochmal die E-Mail Adresse geprüft
     * Wenn diese mit den hinterlegten Daten in der API zusammen stimmt bekammt man den JWT
     * @param {*} param0
     * @param String mail
     */
    confirmMail({ commit, dispatch, state }, mail) {
      return api
        .checkUserMail(state.ticketPermit.code, mail, state.invitation)
        .then((token) => {
          commit("tokenJWT", token);
          dispatch("veryfyContact");
        });
    },

    invitationSessions({ commit, state, dispatch }) {
      return api
        .fetchSessionRegistrations(state.tokenJWT)
        .then((data) => {
          commit("loading", false);
          commit("invitationSessions", data);
          dispatch("");
          return data;
        })
        .catch(() => {
          dispatch("error", "Token invalid in Session Registration");
        });
    },

    /**
     * Hier holt man die sich die Kontaktdaten, wobei es 3 Fälle gibt.
     * Fall 1: ticketPermit ist personalisiert, hier kommt genau 1 Conact zurück
     * Fall 2: es gibt schon ein oder mehrere passende (firstname, lastname, email) hinterlegte Contacts
     * Fall 3: es gibt keine Contact, somit wird automatich ein neuer erstellt (HTTP State 201)
     * @param {*} param0
     */
    veryfyContact({ commit, state, dispatch }) {
      commit("loading", true);

      if (
        /\/invitation/.test(window.location.href) ||
        /invitation=true/.test(window.location.href)
      ) {
        commit("invitation", true);
        commit("step", 3);
        dispatch("sessionWaitlist");

        return api
          .fetchSessionRegistrations(state.tokenJWT)
          .then((data) => {
            commit("loading", false);
            router.push("/invitations");
            commit("step", 3);
            commit("invitationSessions", data);
          })
          .catch(() => {
            dispatch("error", "Token invalid in Session Registration");
          });
      } else {
        commit("invitation", false);
        return dispatch("veryfyContactData");
      }
    },
    veryfyContactData({ commit, state, dispatch, getters }) {
      return api
        .fetchContactData(state.tokenJWT)
        .then(async (data) => {
          commit("loading", false);
          /**
           * wenn es personalisiert ist, ein neuer Contact erstellt wurde, oder wenn nur ein Contact dzu vorhanden ist
           * TODO man kann genauer prüfen, HTTP State 200 oder 201
           */
          if (data.length == 1) {
            if (
              data[0]["efa_ticketpermit_contactid_contact"] &&
              data[0]["efa_ticketpermit_contactid_contact"][0]
            ) {
              const conditionsaccepted =
                data[0]["efa_ticketpermit_contactid_contact"][0][
                  "efa_conditionsaccepted"
                ];
              const gdpraccepted =
                data[0]["efa_ticketpermit_contactid_contact"][0][
                  "efa_gdpraccepted"
                ];
              const codeOfConductaccepted =
                data[0]["efa_codeofconductaccepted"];
              const newConditionsaccepted = data[0]["new_agbakzeptiert"];
              const newGdpraccepted = data[0]["new_datenschutzok"];

              if (
                conditionsaccepted &&
                gdpraccepted &&
                codeOfConductaccepted &&
                newConditionsaccepted &&
                newGdpraccepted
              ) {
                dispatch("selectContact", {
                  contact: data[0],
                  acceptedAgreements: true,
                });
                commit("step", 4);
              } else {
                dispatch("selectContact", {
                  contact: data[0],
                  acceptedAgreements: false,
                });
                commit("step", 3);
              }
            } else {
              dispatch("selectContact", {
                contact: data[0],
                acceptedAgreements: false,
              });
              commit("step", 3);
            }
          } else {
            commit("contactlist", data);
            router.push("/contactlist");
            commit("step", 3);
          }
        })
        .catch(() => {
          commit("loading", false);
          if (!getters.decodedToken.nameid) {
            router.push("register");
          } else {
            dispatch("error", "Verify contact failed.");
          }
        });
    },
    /**
     * Sobald wir eine ContactId haben, können wir einen neuen JWT ausstellen lassen der in der API die Rechte für den Benutzer hat
     * Das brauchen wir um Kontaktdaten zu speichern und um "verifyTicketPermit" aufrufen zu können
     * @param {*} param0
     * @param String mail
     */
    createContactJWT(
      { commit, state, dispatch },
      { contactId, mail, acceptedAgreements, tickets }
    ) {
      commit("loading", true);
      const ticketStrings = tickets.toString();
      const ticketsResult = ticketStrings?.replaceAll(",", ";");
      api
        .generateContactJWT(
          state.ticketPermit.code,
          mail,
          contactId,
          ticketsResult
        )
        .then((token) => {
          commit("loading", false);
          commit("tokenJWT", token);
          if (acceptedAgreements) {
            dispatch("ticket/initialize");
          } else {
            router.push("/contact");
          }
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Authorisation failed.");
        });
    },
    selectContact(
      { commit, getters, dispatch },
      { contact, acceptedAgreements }
    ) {
      const ticketStrings = getters.decodedToken.tickets.toString();
      const tickets = ticketStrings?.replaceAll(",", ";");
      commit("contact", contact);
      dispatch("createContactJWT", {
        contactId: contact.contactid,
        mail: getters.decodedToken.email,
        acceptedAgreements: acceptedAgreements,
        tickets: tickets,
      });
    },
    updateContact({ commit, state, dispatch }, contact) {
      commit("loading", true);
      let updates = mapContactToSave(contact);
      api
        .updateContact(state.tokenJWT, updates)
        .then((contact) => {
          commit("loading", false);
          commit("contact", contact);
          dispatch("ticket/initialize");
        })
        .catch(() => {
          commit("loading", false);
          dispatch("error", "Update contact failed.");
        });
    },
    error({ commit }, message) {
      commit("error", message);
      if (!message) {
        router.push("/");
      }
    },
  },
  modules: {
    metadata,
    ticket,
  },
});
