<template>
  <div class="cv-confirmmail">
    <div class="cv-text--intro cv-margin">
      <h2 class="cv-style--highlight">
        {{ $t("welcome_ticketing_system_headline") }}
      </h2>
      <!-- <div>{{ $t("tickets_on_file") }}</div> -->
    </div>
    <!-- <cv-ticket-permit-info class="cv-margin" /> -->
    <div class="cv-formbox">
      <h3 class="cv-headline">{{ $t("proceed_login") }}</h3>
      <div v-if="error" class="cv-style--error cv-margin--small">
        {{ $t("validate_email_error") }}
        <br />
        {{ $t("validate_email_error_description") }}
      </div>
      <cv-form
        class="cv-form--singleline"
        :loading="loading"
        @submit="submitData"
      >
        <cv-input-field
          v-model="mail"
          type="email"
          :required="true"
          :label="$t('user_mail')"
        />
      </cv-form>
    </div>
  </div>
</template>

<script>
import CvForm from "../components/form/Form.vue";
import CvInputField from "../components/form/InputField.vue";
// import CvTicketPermitInfo from "../components/TicketPermitInfo.vue";

export default {
  components: {
    CvForm,
    CvInputField,
  },
  data: () => ({
    mail: "",
    loading: false,
    error: null,
  }),
  methods: {
    submitData() {
      this.loading = true;
      this.error = false;
      let promise = this.$store.dispatch("confirmMail", this.mail);
      promise.catch(() => {
        this.loading = false;
        this.error = true;
      });
    },
  },
};
</script>

<style lang="scss"></style>
