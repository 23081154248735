/* eslint-disable */
const translations = {
  // step_verification: "Verifikation",
  // step_registration: "Registrierung",
  // step_contact: "Kontaktinformationen",
  // step_tickets: "Tickets",

  // ticket_already_redeemed: "Der Ticket-Code wurde bereits eingelöst!", 
  // ticket_already_got_confirmationmail: "Für diesen eingelösten Ticket-Code wurde bereits ein Bestätigungsmail verschickt.",
  // ticket_mail_contains_confirmationcode: "Dieses Mail beinhaltet einen Zugriffscode für die Registrierung.",
  // ticket_mail_resend_confirmationcode: "Sollten Sie dieses E-Mail nicht erhalten haben, können Sie es sich untenstehend nochmals zusenden lassen.",
  // resend_email: "Link/E-Mail erneut senden",
  // tickets_on_file: "Folgendes Ticket wurde für Sie hinterlegt.",
  // proceed_login: "Anmeldung fortsetzen",

  // registration_thank_you_text: "Wir freuen uns über Ihre Teilnahme am heurigen Forum „The Great Transformation”. Wir bitten Sie, die untenstehenden Kontaktinformationen auszufüllen.", 
  // user_your_person: "Zu Ihrer Person",
  // user_mail_preferred: "Bevorzugte E-Mail Adresse",
  // user_mail_business: "berufliche E-Mail Adresse",
  // user_mail: "E-Mail",
  // user_birthdate: "Geburtsdatum",
  // user_gender: "Geschlecht",
  // user_language: "Sprache",
  // user_telephone: "Telefon",
  // user_mobilephone: "Mobiltelefon",
  // user_emergencycontact: "Notfallkontakt: Bitte folgende Informationen angeben: Nachname, Vorname, Mobiltelefon (inkl. Landesvorwahl, z.B. +43 664 123 45 67), Email.)",
  // user_company: "Firma",
  // user_company_position: "Berufsbezeichnung", 
  // user_company_industry: "Branche",
  // user_address_preferred: "bevorzugte Adresse",
  // user_address_street: "Straße",
  // user_address_zip: "Postleitzahl",
  // user_address_city: "Stadt",
  // user_address_country: "Land",
  // user_address_company: "Firma",
  // user_address_uid: "Umsatzsteuernummer",
  // user_dsgvo_headline: "Datenschutz",
  // user_donotbulkemail: "digitale Aussendungen", 
  // user_dsgvo_text: "Zur Vorbereitung, Durchführung und Auswertung werden wir Ihre Daten elektronisch verarbeiten. Wir werden Ihnen gelegentlich Informationen und Einladungen zu ähnlichen Veranstaltungen in der Zukunft zukommen lassen. Bitte beachten Sie, dass während der Veranstaltung Fotos und Videos (Live-Streams oder Aufzeichnungen) gemacht werden. Um von Ihrem Widerspruchsrecht Gebrauch zu machen, senden Sie uns eine E-Mail an datenschutz@alpbach.org.",
  // user_agb: "I hereby declare to have read and accepted the General Terms and Conditions of Business of the European Forum Alpbach Non-Profit Association.",
  // user_dsgvo: "Ich erkläre hiermit, die Allgemeinen Geschäftsbedingungen des Europäischen Forums Alpbachs gelesen und akzeptiert zu haben.",
  // usercode_of_conduct: "Ich habe den Code of Conduct des Europäischen Forums Alpbach gelesen.",
  // user_scholarship_terms: "Hiermit erkläre ich, die Stipendienbedingungen der gemeinnützigen Privatstiftung Europäisches Forum Alpbach gelesen und akzeptiert zu haben.",
  // user_einvoice: "If you need an e-Invoice, please contact accounting@alpbach.org",
  // user_invoice_address: "abweichende Rechnungsadresse",

  // user_preferred_mail: "bevorzuge E-Mail Adresse",

  // user_firstname: "Vorname",
  // user_lastname: "Nachname",
  // welcome_ticketing_system_headline: "Willkommen im EFA-Ticketing-System",
  // welcome_ticketing_system_text: "Hier können Sie Ihren Ticket-Code für unsere Online- und Offline-Events einlösen und die für Sie passenden Tickets erwerben.",
  // your_ticket_code: "Ihr Ticket-Code",
  // submit: "Weiter",

  // tickets_all_options: "Untenstehend finden Sie alle für Sie vorgesehenen Ticket-Möglichkeiten.",
  // tickets_more_options: "Falls Sie mehere Ticket-Codes erhalten haben, können Sie diese hier einlösen",
  // tickets_feel_free_to_repeat: "Sie können diesen Schritt gerne mehrfach ausführen. Ihre persönliche Ticket-Auswahl bleibt auch nach dem Kauf einer oder mehrerer Tickets bestehen.",
  // tickets_personal_tickets_headline: "Persönliche Tickets",
  // tickets_public_tickets_headline: "Öffentliche Tickets",
  // tickets_redeem_code: "Ticket-Codes einlösen",
  // tickets_checkout: "abschließen",
  // ticket_permission_by_efa: "genehmigung durch EFA benötigt",
  // ticket_needs_survey: "Umfrage benötigt",
  // ticket_select: "auswählen",

  // payment_headline: "Bezahlung",
  // thank_you: "Danke",
  // ticket_approval_required_text: "Ein oder mehrere Ihrer gewählten Tickets muss von EFA bestätigt werden. Sie werden per E-Mail benachrichtigt.", 
  // ticket_survey_required_text: "Ein oder mehrere Ihrer gewählten Tickets benötigt eine Teilnahme an einer Umfrage. Sie werden per E-Mail benachrichtigt.",
  // proceed_registration: "Registrierung fortsetzen",
  // registration_submitted: "Vielen Dank, ihre Registrierung wurde erfolgreich übermittelt!",
  // registration_mail_will_be_transmitted: "Ihnen wird zeitnah ein E-Mail mit dem weiteren Registrierungsprozess zugestellt.",
  // to_start_page: "zur Startseite",

  // user_headline_person: "Zu Ihrer Person",
  // user_intro_text: "Wir freuen uns über Ihre Teilnahme am heurigen Forum „The Great Transformation”. Wir bitten Sie, die untenstehenden Kontaktinformationen auszufüllen.",
  // user_headline_company: "Unternehmen & Position",
  // user_headline_address: "Adresse",
  // user_headline_payment: "Bezahlung",

  // needs_approval: "zu genehmigen",
  // payable: "zahlbar",
  // user_text_email_infotext: "Bitte verwenden Sie Ihre persönliche E-Mail Adresse. Verwenden Sie nicht die gleiche E-Mail Adresse für mehrere Personen (z.B. Büro- oder Firmenaccounts).",

};

export default translations;
