/* eslint-disable */
const translations = {
  step_verification: "Start",
  step_registration: "Verification",
  step_contact: "Contact",
  step_tickets: "Tickets",
  step_basket: "Cart",
  step_invitations: "Invitations",

  ticket_already_redeemed: "Ticket was already redeemed!",
  ticket_already_got_confirmationmail:
    "A confirmation e-mail has already been sent for this ticket code.",
  ticket_mail_contains_confirmationcode:
    "This mail contains an ticket code for the registration.",
  ticket_mail_resend_confirmationcode:
    "If you have not received this e-mail, you can resend it below.",
  resend_email: "Resend e-mail",
  tickets_on_file: "The following ticket has been saved for you.",
  proceed_login: "Continue Registration",

  validate_email_error: "The given e-mail address is not valid.",
  validate_email_error_description:
    "Please use the exact same address that you received the e-mail from.",

  register_token_expire_info:
    "You will receive an e-mail with your personal confirmation link. This link is valid for 6 hours.",

  user_address_mail: "Preferred contact",

  registration_thank_you_text:
    'We look forward to your participation in this year\'s Forum "The Great Transformation". We kindly ask you to fill in the contact information below.',
  user_your_person: "About yourself",
  user_mail_preferred: "Preferred e-mail address",
  user_mail_business: "Company e-mail address",
  user_mail: "E-mail",
  user_birthdate: "Date of birth",
  user_gender: "Gender",
  user_language: "Language",
  user_telephone: "Telephone",
  user_mobilephone: "Mobile phone",
  user_telephone_business: "Business telephone",
  user_mobilephone_business: "Business mobile phone",
  user_emergencycontact:
    "Emergency contact: Please provide the following information: Last name, First name, Mobile (format with country code e.g. +43 664 123 45 67), E-mail",
  user_company: "Organisation",
  user_company_position: "Position",
  user_company_field_of_activity: "Field of Activity",
  user_company_industry: "Industry",
  user_address_preferred: "Preferred address",
  user_address_street: "Street",
  user_address_zip: "ZIP",
  user_address_city: "City",
  user_address_country: "Country",
  user_address_company: "Company",
  user_address_uid: "VAT Number",
  user_dsgvo_headline: "Data protection",
  user_donotbulkemail: "digital mailings",
  user_dsgvo_text:
    "For preperation, facilitation and evaluation, we will electronically process your data. We will occasionally share with you information and invitations to similar events in the future. Please note that photos and videos (live streams or recordings) will be taken during the event. To exercise your right to object, send us an e-mail to datenschutz@alpbach.org.",
  user_agb:
    "I hereby declare to have read and accepted the General Terms and Conditions of Business of the European Forum Alpbach Non-Profit Association.",
  user_dsgvo: "I have read the Privacy Policy of the European Forum Alpbach.",
  usercode_of_conduct:
    "I have read the Code of Conduct of the European Forum Alpbach.",
  user_scholarship_terms:
    "I hereby declare to have read and accepted the Scholarship Terms and Conditions of the European Forum Alpbach Non-Profit Private Foundation",
  user_invoice_address: "Billing address differs from address given above",
  user_mail_private: "Private e-mail address",
  user_mail_business: "Business e-mail address",
  user_other_field_of_activity: "Please specify your field of activity",
  user_other_field_of_industry: "Please specify your industry",
  user_other_position: "Please specify your position",
  personal_tickets_assigned: "Personal tickets are assigned especially to you.",
  member_of_oe1:
    "Are you a member of Ö1? You are entitled to -10% (on-site attendance only); send us an e-mail before you finish your registration to registration@alpbach.org with a confirmation of the membership attached.",
  user_nationality: "Nationality",
  ticket_paid_text:
    "Your ticket(s) will be sent to you by e-mail.  It may take up to 15 minutes for the e-mail to arrive in your inbox. Please also check your spam folder.",
  user_address_used_for_billing:
    "This address will be used as the billing address unless otherwise specified below in the section 'Billing information'.",

  currentlyNoTicketsInBasket:
    "Currently you do not have any tickets in your personal basket. Please select one or more ticket(s) from the sections below. Once confirmed by clicking checkout on the bottom, those tickets are added to your personal basket.",
  followingTicketsAreAdditional:
    "The following tickets can be purchased based on your voucher code",

  user_text_why_company:
    "Details of organisation and position are relevant for the list of participants",

  efa_attendancedatatransfer:
    "Don't miss great networking opportunities at exclusive events hosted by partner organisations of the European Forum Alpbach! Make sure to check this box and allow the European Forum Alpbach to inform its partners about your attendance and forward your name, position, organisation, and country.",

  track_interest: "Which track is of specific interest to you?",

  your_ticket_for_the_digital_conference:
    "Get your ticket for the Digital Conference",
  get_your_ticket: "Get Your Ticket",
  get_your_digital_ticket: "Ticket for digital conference",

  user_show_pronouns: "Show my pronouns on the badge",
  user_pronouns: "Pronouns",
  user_pronouns_other: "What is your pronouns?",
  user_firstname: "First name",
  user_lastname: "Last name",
  welcome_ticketing_system_headline: "EFA Ticketshop",
  welcome_ticketing_system_text:
    "Redeem your ticket code for our online and offline events as well as purchase new tickets for the European Forum Alpbach.",
  your_ticket_code: "Your ticket code",
  submit: "Submit",
  submit_coupon: "Redeem",

  codeNotNeeded:
    "Only relevant if you have received a ticket code you didn’t redeem yet. If you have received multiple ticket codes, please redeem them one by one.",
  paymentWithCardsOnly:
    "Payment by credit card and SEPA only. An invoice will be sent to you after your purchase.",

  you_can_select_more_tickets_later:
    "In this step you can only select one ticket module. In the next step you can select and purchase additional ticket modules.",

  user_preferred_mail: "preferred e-mail address",

  tickets_all_options: "Here are all the ticket options available for you.",
  tickets_more_options: "",
  tickets_feel_free_to_repeat:
    "You are welcome to complete out this step more than once. Your personal ticket selection will remain even after you have purchased one or more tickets.",
  tickets_personal_tickets_headline: "Personal Tickets",
  tickets_public_tickets_headline: "Public Tickets",
  tickets_redeem_code: "Redeem ticket codes",
  tickets_checkout_label: "Your selection",
  tickets_checkout_ticket_count: "{{count}} ticket",
  tickets_checkout_ticket_count_plural: "{{count}} tickets",
  tickets_checkout: "Checkout",
  ticket_permission_by_efa: "Approval by EFA required",
  ticket_permission_by_efa_approved: "Approval by EFA approved",
  ticket_permission_by_efa_pending: "Approval by EFA pending",
  ticket_permission_refused: "Approval by EFA refused",
  ticket_permission_bookableafter: "Bookable afterwards",
  ticket_permission_bookable: "Bookable",
  ticket_permission_soldout: "sold out",
  ticket_permission_payment_pending: "payment pending",
  ticket_permission_paid: "paid",
  ticket_needs_survey: "survey required",
  ticket_needs_survey_pending: "survey pending",
  ticket_needs_survey_completed: "survey completed",
  ticket_select: "select",
  ticket_unselect: "Selected",
  ticket_buy: "Select",
  subtickets_label: "This ticket category includes the following admissions",
  ticket_additional_state_sold_out: "sold out",
  ticket_additional_state_already_purchased: "already purchased",
  ticket_additional_state_already_selected: "already selected",
  ticket_additional_state_refused: "refused",
  ticket_approval_yes: "Approval by EFA required",
  ticket_approval_no: "By EFA approved",
  tickets_next: "Next",

  addcoupon_input: "Enter ticket code",

  basket_headline: "Cart",

  payment_headline: "Payment",
  thank_you: "Thank you!",
  stay_tuned: "Stay tuned!",
  you_are_in: "You're in!",
  ticket_approval_required_text:
    "One or more of your selected tickets must be confirmed by EFA. You will be notified by e-mail.",
  ticket_survey_required_text:
    "One or more of your selected tickets requires participation in a survey. You will be notified by e-mail.",
  proceed_registration: "Continue registration",
  registration_submitted: "Halfway done!",
  registration_mail_will_be_transmitted:
    "Please check your e-mail inbox to verify your data.",
  check_spam:
    "If you didn't receive any confirmation e-mail, whether in your inbox or spam, please contact registration@alpbach.org.",
  to_start_page: "Back to start",

  user_headline_person: "About you",
  user_intro_text: "It’s your turn: Please fill in the contact details below.",
  user_headline_company: "Organisation & Position",
  user_headline_address: "Address",
  user_headline_payment: "Billing",

  payment_confirmed_tickets: "Free",
  free_tickets_are_confirmed:
    "Tickets that are free of charge and do not need an approval by EFA will automatically be activated once you click checkout.",
  needs_approval: "to be approved",
  payable: "Payable",
  paymenttype_preferred: "Preferred payment method",
  stripe_input: "Credit Card information",

  stripe_sepa_cardholder_input: "Card holder",
  stripe_sepa_email_input: "E-mail",
  stripe_sepa_iban: "Iban",

  error_headline: "An error occured",
  error_button: "start over again",
  user_text_email_infotext:
    "Please use your personal e-mail address. Do not use the same e-mail address for more than one person (e.g. office, company or team assistant account).",

  back_button: "Back",
  back_button_to_start: "Back to start",
  back_button_to_contact: "Back to contact",
  back_button_to_tickets: "Back to tickets",
  back_button_cancel: "Cancel and go back to start",

  select_ticket: "Select",

  infobox_contact_headline: "Why do you need my contact details now?",
  infobox_contact_intro:
    "To provide you with the best possible booking experience, we need your contact details.",
  infobox_contact_content: "This way, we can check if:",
  infobox_contact_content_li_01: "someone already put aside a ticket for you",
  infobox_contact_content_li_02: "a partner has invited you for a ticket",
  infobox_contact_content_li_03:
    "your company has access to a ticket contingent",
  infobox_contact_content_li_04:
    "you qualify for a special ticket (e.g. reduced ticket)",

  infobox_approval_headline: "Why does my ticket require approval by EFA?",
  infobox_approval_content:
    "One or more of your selected tickets needs to be approved by EFA. Reasons for this can be:",
  infobox_approval_content_end:
    "You get charged AFTER your ticket gets approved by EFA.",
  infobox_approval_content_li_01: "you qualify for a sponsored ticket ",

  approval_headline: "Approval required",

  additional_headline: "Additional Tickets",

  personal_tickets_description:
    "Personal tickets are assigned especially to you.",

  alpbach_home: "Alpbach home",

  total_price_label: "Total price:",
  total_net_price_label: "Subtotal:",
  vat_label: "Tax (10% VAT):",

  overview_invitations: "Overview of your network session status:",
  open_invitations: "New network session invitation:",
  upcoming_sessions: "Your network sessions:",
  invitation_accept: "Accept",
  invitation_accepted: "Accepted",
  invitation_reject: "Decline",
  invitation_rejected: "Declined",
  no_open_invitations: "Currently there are no pending invitations",
  no_upcoming_sessions: "Currently there are no upcoming sessions",
  invitation_from: "Invitation from",
  invitation_date: "Date and Time",
  invitation_fully_booked: "Fully booked",
  invitation_location: "Location",
  invitation_accept_text: "Your answer has been processed successfully",

  waitlist_one: "{{count}} person is on the waitlist",
  waitlist_other: "{{count}} people are on the waitlist",
};

export default translations;
