<template>
  <div class="cv-ticket" :class="classes">
    <div class="cv-ticket__head">
      <header class="first-row">
        <div class="icon-name-wrapper">
          <div class="cv-ticket__icon">
            <span class="bh-font-ticket"></span>
          </div>
          <h4 class="cv-ticket__name">{{ name }}</h4>
        </div>
        <cv-price class="cv-ticket__price" :price="ticket.efa_totalprice" :originalPrice="ticket.efa_price" />
      </header>
      <div class="second-row">
        <div class="cv-ticket__content">
          <cv-ticket-sessions class="cv-ticket__sessions" :ticket="ticket" />
          <cv-subtickets class="cv-ticket__subtickets" :ticket="ticket" />
          <div
            v-if="description"
            class="cv-ticket__description"
            v-html="cleanHTML"
          ></div>
          <div class="state-wrapper">
            <cv-date class="cv-ticket__date" :validFrom="ticket.efa_ticketcategory.efa_validfrom" :validTo="ticket.efa_ticketcategory.efa_validto"></cv-date>
            <cv-ticket-state class="cv-ticket__states" :ticket="ticket" />
          </div>
        </div>
        <div class="cv-ticket__tools">
          <template v-if="isSelectable && state != 'disabled'">
            <button
              v-if="!isSelected"
              class="cv-ticket__button cv-button"
              @click="select(ticket)"
            >
              <template v-if="isBookable">
                {{ $t("ticket_buy") }}
              </template>
              <template v-else>
                {{ $t("ticket_select") }}
              </template>
            </button>
            <button
              v-else
              class="cv-ticket__button cv-ticket__button--selected cv-button"
              @click="unselect(ticket)"
            >
              {{ $t("ticket_unselect") }}
            </button>
          </template>
          <div v-else class="cv-ticket__state cv-badge">{{ stateLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CvPrice from "./Price.vue";
import CvTicketSessions from "./TicketSessions.vue";
import CvSubtickets from "./Subtickets.vue";
import CvTicketState from "./TicketState.vue";
import CvDate from "./Date.vue";
import sanitizeHtml from "sanitize-html";

import {
  isSoldout,
  isBookable,
  isSelectable,
  isStateActive,
  isStateCompleted,
  isStatePending,
  getApprovedState,
} from "../utils/ticketstates";

export default {
  props: {
    ticket: Object,
  },
  components: {
    CvPrice,
    CvTicketSessions,
    CvTicketState,
    CvSubtickets,
    CvDate,
  },
  computed: {
    name() {
      return this.ticket[
        "_efa_ticketcategoryid_value@OData.Community.Display.V1.FormattedValue"
      ];
    },
    isApprovalRequired() {
      return this.$store.getters["ticket/isApprovalRequired"](this.ticket);
    },
    similarTickets() {
      return this.$store.getters["ticket/similarTickets"](this.ticket);
    },
    similarTicketsSelected() {
      return this.$store.getters["ticket/similarTicketsSelected"](this.ticket);
    },
    similarTicketsPaid() {
      return this.$store.getters["ticket/similarTicketsPaid"](this.ticket);
    },
    ticketsFirstMounted() {
      return this.$store.getters["ticket/ticketsFirstMounted"];
    },
    description() {
      return this.ticket.efa_ticketcategory.efa_description;
    },
    stateLabel() {
      if (isSoldout(this.ticket)) {
        return this.$t("ticket_additional_state_sold_out");
      } else if (getApprovedState(this.ticket) == -1) {
        return this.$t("ticket_additional_state_refused");
      } else if (
        this.similarTicketsPaid.length &&
        !isStatePending(this.ticket)
      ) {
        return this.$t("ticket_additional_state_already_purchased");
      } else if (
        this.similarTicketsSelected.length &&
        !isStatePending(this.ticket)
      ) {
        return this.$t("ticket_additional_state_already_selected");
      } else {
        return this.ticket[
          "statuscode@OData.Community.Display.V1.FormattedValue"
        ];
      }
    },
    state() {
      if (isSoldout(this.ticket)) {
        return "inactive";
      } else if (isStatePending(this.ticket)) {
        return "pending";
      } else if (
        this.similarTicketsSelected.length ||
        this.similarTicketsPaid.length
      ) {
        return "disabled";
      } else if (isStateActive(this.ticket)) {
        return "active";
      } else if (isStateCompleted(this.ticket)) {
        return "complete";
      } else {
        return "inactive";
      }
    },
    isSelected() {
      return this.$store.getters["ticket/isSelected"](this.ticket);
    },
    classes() {
      let stateClass = "cv-ticket--" + this.state;
      let classes = [stateClass];
      if (this.isSelected) {
        classes.push("cv-ticket--selected");
      }
      return classes;
    },
    isSelectable() {
      return isSelectable(this.ticket);
    },
    isBookable() {
      return isBookable(this.ticket);
    },
    cleanHTML() {
      return sanitizeHtml(this.description, {
        allowedTags: ["br", "div", "p", "ol", "ul", "li"],
      });
    },
  },
  methods: {
    select(ticket) {
      this.$store.dispatch("ticket/selectItem", ticket);
    },
    unselect(ticket) {
      this.$store.dispatch("ticket/unselectItem", ticket);
    },
  },
  mounted() {
    if (this.ticketsFirstMounted) {
      if (this.$store.getters.decodedToken.tickets) {
        this.$store.getters.decodedToken.tickets.forEach((ticketID) => {
          if (ticketID == this.ticket["_efa_ticketpermitid_value"]) {
            if (this.ticket["statuscode"] != 221120006) {
              this.select(this.ticket);
            } else {
              this.unselect(this.ticket);
            }
          }
        });
      }
    }

    // if ticket is purchased but is available in other categories -> unselect ticket
    if (this.similarTicketsPaid.length && !isStatePending(this.ticket)) {
      this.unselect(this.ticket);
    }
  },
};
</script>

<style lang="scss">
@mixin ticketstate($color) {
  background: rgba($color, 0.2);
  &:before {
    background: $color;
  }
  .cv-ticket__icon {
    color: $color;
  }
}

.cv-ticket {
  position: relative;
  background: $color-gold-light;
  overflow: hidden;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &.cv-ticket--disabled {
    @include ticketstate($color-gray);
    opacity: 0.5;
  }
  &.cv-ticket--active {
    @include ticketstate($color-gold);
  }
  &.cv-ticket--complete {
    @include ticketstate($color-green);
  }
  &.cv-ticket--pending {
    @include ticketstate($color-orange);
  }
  &.cv-ticket--inactive {
    @include ticketstate($color-red);
  }

  &.cv-ticket--selected {
    background: black;
    color: white;

    .cv-ticket__head,
    .cv-ticket__icon,
    .cv-ticket__content,
    .cv-ticket__date,
    .cv-ticket__tools {
      background: black;
      color: white;

      .cv-ticket__name,
      .cv-price__value,
      .cv-price__value small,
      .cv-ticket-state__label {
        color: white;
      }
    }

    .label-approved,
    .cv-ticket__description {
      color: white;
    }

    .cv-ticket__button--selected {
      border: 1px solid white;
      background-color: transparent;
      color: white;
      display: flex;

      &::before {
        content: "";
        background-image: url("../assets/sass/ic_checkmark.svg");
        background-repeat: no-repeat;
        background-size: 24px;
        height: 24px;
        width: 24px;
        margin-right: 16px;
        display: block;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }

      &:hover {
        background-color: transparent;

        &::after {
          margin-left: 22px;
        }
      }
    }
    .cv-price__percentage {
      color: $color-white;
    }
  }

  .cv-price__value {
    flex-shrink: 0;
    white-space: nowrap;
  }
}

.cv-ticket__head {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 0px;
  padding: 32px;
  background: white;

  @media (max-width: 768px) {
    padding: 15px;
    flex-direction: column;
  }
}
.first-row,
.second-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.first-row {
  margin-bottom: 12px;
}
.second-row {
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: flex-end;
    flex-direction: row;
  }
}
.icon-name-wrapper {
  display: flex;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    justify-content: center;
  }
}
.cv-ticket__icon {
  width: 40px;
  font-size: unit(1.5);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  background: white;

  span {
    line-height: 30px;
  }

  @media (min-width: 768px) {
    width: 50px;
  }
}
.cv-ticket__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  background: white;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
}
.cv-ticket__name {
  color: $color-headings-dark;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  font-weight: 700;
  font-family: "degular";
  padding-right: 0.5rem;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 32px;
  }
}

.cv-ticket__subtickets {
  margin: unit(1) 0;
}
.cv-ticket__description {
  margin-top: unit(0.5);
  font-family: "degular";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #303139;
  white-space: pre-wrap;
}

.cv-ticket__states {
  margin-top: auto;

  @media (max-width: 768px) {
    margin-top: 8px;
    width: 100%;
  }
}

.cv-ticket__tools {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba($color-gold, 0.2);

  @media (min-width: 768px) {
    // display: block;
    // width: 100%;
    display: flex;
    align-items: flex-end;
    flex-shrink: 1;
    width: 25%;
  }
}

.cv-price {
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    display: flex;
  }
}

.cv-ticket__state.cv-ticket__state {
  justify-content: center;
  border-radius: 0;
}

.cv-ticket__price {
  margin-bottom: unit(1);
}

//TODO button alternativen global
.second-row .cv-ticket__button.cv-ticket__button {
  color: $color-white;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: block;
  font-weight: 600;
  font-family: "degular";
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 24px;
  padding-left: 32px;
  padding-top: 12px;
  padding-right: 32px;
  padding-bottom: 12px;
  width: 100%;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
    width: fit-content;
  }
}
.cv-ticket__button--selected.cv-ticket__button--selected {
  background: rgba($color-white, 0.3);
  color: $color-green;
  &:hover {
    background: rgba($color-white, 0.2);
  }
}

.state-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 32px;
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #303139;
}

.cv-ticket .cv-ticket__state.cv-ticket__state {
  align-self: flex-end;
}
</style>
