<template>
  <div v-if="sessions.length" class="cv-ticket-sessions">
    <cv-ticket-session
      v-for="session in sessions"
      :key="session.msevtmgt_sessiontrackid"
      :ticket="ticket"
      :session="session"
    />
  </div>
</template>

<script>
import CvTicketSession from "./TicketSession.vue";

export default {
  components: {
    CvTicketSession,
  },
  props: {
    ticket: Object,
  },
  computed: {
    sessions() {
      return this.ticket.efa_ticketcategory.efa_ticketcategorysessiontracks;
    },
  },
};
</script>

<style lang="scss">
.cv-ticket__sessions {
  margin-bottom: unit(-0.2);
}
</style>
