<template>
  <div class="cv-approval-required-info-box">
    <!-- Infobox -->
    <div class="cv-infobox-toggler-wrapper">
      <div class="text-box">
        <h3 class="cv-infobox-intro bold">
          {{ $t("infobox_approval_headline") }}
        </h3>
      </div>
      <!-- Hidden Infobox Content -->
      <div
        :class="{
          'cv-infobox-content': true,
          'cv-infobox-visible': additionalExpanded,
        }"
      >
        <transition name="cv-slide-fade">
          <div class="cv-content">
            <p>{{ $t("infobox_approval_content") }}</p>
            <ul>
              <li>{{ $t("infobox_approval_content_li_01") }}</li>
            </ul>
            <p>{{ $t("infobox_approval_content_end") }}</p>
          </div>
        </transition>
      </div>
      <!-- Infobox toggle button -->
      <div class="button-wrapper">
        <div
          :class="{
            'cv-infobox-toggler': true,
            'cv-infobox-toggler-active': additionalExpanded,
          }"
          v-on:click="additionalExpanded = !additionalExpanded"
        >
          <div class="button-text">
            {{ additionalExpanded ? "Show less" : "Learn more" }}
          </div>
          <div
            :class="{
              'image-container': true,
              'open-box': additionalExpanded,
            }"
          >
            <img src="../assets/sass/ic_chevron_open_tab.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    additionalExpanded: false,
  }),
};
</script>

<style>
.cv-approval-required-info-box .cv-infobox-intro.bold {
  font-family: "Degular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #121212;
}

.cv-approval-required-info-box .cv-content {
  font-family: "Degular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #303139;
}

.cv-infobox-toggler {
  cursor: pointer;
}
</style>
