import i18next from "i18next";
import de from "./de";
import en from "./en";

i18next.init(
  {
    lng: "en", //document.documentElement.getAttribute("lang").substring(0, 2)
    fallbackLng: "en",
    supportedLngs: ["en", "de"],
    initImmediate: false,
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
  },
  (err) => {
    if (err) {
      return console.log("something went wrong loading", err);
    }
  }
);

i18next.on('languageChanged', (language) => {
  document.documentElement.setAttribute('lang', language);
});

const plugin = {
  install: (app) => {
    app.config.globalProperties.$t = (keys, options) => {
      return i18next.t(keys, options);
    };
  },
};

export default plugin;
