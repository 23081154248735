<template>
  <div class="cv-formbox">
    <cv-form v-show="!loading" @submit.prevent="submit">
      <div class="cv-field">
        <div ref="stripe" class="cv-stripe__input"></div>
        <label class="cv-field__label">{{ $t("stripe_input") }}</label>
      </div>
      <div class="cv-style--error">{{ error.message }}</div>
    </cv-form>
    <cv-loading v-if="loading" />
  </div>
</template>

<script>
import CvForm from "../form/Form.vue";
import CvLoading from "../Loading.vue";
import { getStripe } from "../../utils/stripe";

export default {
  components: {
    CvForm,
    CvLoading,
  },
  computed: {
    clientSecret() {
      return this.$store.state.ticket.paymentData.client_secret;
    },
    billingData() {
      return this.$store.getters["ticket/billingData"];
    },
  },
  data: () => ({
    card: null,
    stripe: null,
    error: "",
    loading: false,
  }),
  methods: {
    submit() {
      //TODO prozess besser abbilden und in store, achtung loading zerstört stripe
      this.loading = true;
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.billingData.name,
              email: this.billingData.mail,
            },
          },
        })
        .then((result) => {
          this.loading = false;
          if (result.paymentIntent) {
            this.$store.dispatch("ticket/paymentSucceded");
          } else {
            this.error = result.error;
          }
        });
    },
  },
  mounted() {
    this.stripe = getStripe();
    let elements = this.stripe.elements();
    this.card = elements.create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontFamily: "Mundo Sans Std, Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
        },
        invalid: {
          iconColor: "#D33F11",
          color: "#D33F11",
        },
      },
    });
    this.card.mount(this.$refs.stripe);
  },
};
</script>

<style lang="scss">
.cv-stripe__input {
  @extend %basic-field;

  display: flex;
  align-items: center;

  & > * {
    width: 100%;
  }
}
</style>
