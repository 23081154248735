<template>
  <div class="cv-formbox" v-if="showSepaData">
    <cv-form v-show="!loading" @submit.prevent="submit">
      <div class="cv-form-columns">
        <cv-input-field
          class="cv-form-column"
          v-model="user.name"
          type="name"
          :required="true"
          :label="$t('stripe_sepa_cardholder_input')"
        />
        <cv-input-field
          class="cv-form-column"
          v-model="user.email"
          type="email"
          :required="true"
          :label="$t('stripe_sepa_email_input')"
        />
      </div>
      <div class="cv-field">
        <div ref="stripe" class="cv-stripe__input"></div>
        <label class="cv-field__label">{{ $t("stripe_sepa_iban") }}</label>
      </div>
      <div class="cv-style--error">{{ error.message }}</div>
      <cv-infobox>
        By providing your payment information and confirming this payment, you
        authorise (A) Rocket Rides and Stripe, our payment service provider
        and/or PPRO, its local service provider, to send instructions to your
        bank to debit your account and (B) your bank to debit your account in
        accordance with those instructions. As part of your rights, you are
        entitled to a refund from your bank under the terms and conditions of
        your agreement with your bank. A refund must be claimed within 8 weeks
        starting from the date on which your account was debited. Your rights
        are explained in a statement that you can obtain from your bank.
      </cv-infobox>
    </cv-form>
    <cv-loading v-if="loading" />
  </div>
</template>

<script>
import CvForm from "../form/Form.vue";
import CvInputField from "../form/InputField.vue";
import CvLoading from "../Loading.vue";
import CvInfobox from "../Infobox.vue";
import { getStripe } from "../../utils/stripe";

export default {
  components: {
    CvForm,
    CvInputField,
    CvLoading,
    CvInfobox,
  },
  props: {
    showSepaData: Boolean,
  },
  computed: {
    clientSecret() {
      return this.$store.state.ticket.paymentData.client_secret;
    },
    billingData() {
      return this.$store.getters["ticket/billingData"];
    },
  },
  data: () => ({
    iban: null,
    user: {
      name: "",
      email: "",
    },
    stripe: null,
    error: "",
    loading: false,
  }),
  methods: {
    submit() {
      //TODO prozess besser abbilden und in store, achtung loading zerstört stripe
      this.loading = true;
      this.stripe
        .confirmSepaDebitPayment(this.clientSecret, {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: this.user,
          },
        })
        .then((result) => {
          this.loading = false;
          if (result.paymentIntent) {
            this.$store.dispatch("ticket/paymentSucceded");
          } else {
            this.error = result.error;
          }
        });
    },
  },
  mounted() {
    if (this.showSepaData) {
      this.user.name = this.billingData.name;
      this.user.email = this.billingData.mail;

      this.stripe = getStripe();
      let elements = this.stripe.elements();
      this.iban = elements.create("iban", {
        style: {
          base: {
            iconColor: "#000",
            color: "#000",
            fontFamily: "Mundo Sans Std, Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
          invalid: {
            iconColor: "#D33F11",
            color: "#D33F11",
          },
        },
        supportedCountries: ["SEPA"],
        // Elements can use a placeholder as an example IBAN that reflects
        // the IBAN format of your customer's country. If you know your
        // customer's country, we recommend that you pass it to the Element as the
        // placeholderCountry.
        placeholderCountry: "AT",
      });
      this.iban.mount(this.$refs.stripe);
    }
  },
};
</script>

<style lang="scss">
.cv-stripe__input {
  @extend %basic-field;

  display: flex;
  align-items: center;

  & > * {
    width: 100%;
  }
}
</style>
