import axios from "axios";
import * as Sentry from "@sentry/vue";

import { useMock } from "../utils";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

// export const baseUrl = "https://www.alpbach.org/api/"; // live page
// export const baseUrl = "https://16d8tl3j-m643.dotsandlines-cloud.at/api/";
// export const baseUrl = "http://alpbach-craft-cms.test/api/";
// export const baseUrl = "https://kw93tf14-u5l8.dotsandlines-cloud.at/api/";
export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.alpbach.org/api/"
    : "https://j013y4fo-d6w5.dotsandlines-cloud.at/api/"; // current staging link

// https + staging url craft
const extractDataPromise = function (promise) {
  let dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

const extractValuePromise = function (promise) {
  let dataPromise = promise.then((response) => response.data.value);
  return dataPromise;
};

const extractValueFirstPromise = function (promise) {
  let dataPromise = promise.then((response) => response.data.value[0]);
  return dataPromise;
};

const extractTokenPromise = function (promise) {
  let dataPromise = promise.then((response) => response.data.Token);
  return dataPromise;
};

const generateJWT = function (params) {
  let promise = axios.get(baseUrl + "ows/issuetoken", {
    params,
  });
  return extractTokenPromise(promise);
};
const createUser = function (code, firstname, lastname, email, tickets) {
  return generateJWT({
    code,
    firstname,
    lastname,
    email,
    tickets,
  });
};

const checkUserMail = function (code, email, invitation = false) {
  return generateJWT({
    code,
    email,
    invitation,
  });
};

const generateContactJWT = function (code, email, id, tickets) {
  return generateJWT({
    code,
    email,
    id,
    tickets,
  });
};

const fetchContactData = function (token) {
  let promise = axios.get(baseUrl + "verifycontact", {
    params: {
      t: token,
    },
  });
  return extractValuePromise(promise);
};

const updateContact = function (token, data) {
  let promise = axios.post(baseUrl + "updatecontact", data, {
    params: {
      t: token,
    },
  });
  return extractValueFirstPromise(promise);
};

const fetchSessionRegistrations = async function (token) {
  if (process.env.NODE_ENV === "development" && useMock()) {
    const mock = await import("./mocks/fetchSessionRegistrations.json");
    return mock.default;
  }

  let promise = axios.get(baseUrl + "sessionregistrations", {
    params: {
      t: token,
    },
  });
  return extractValuePromise(promise);
};

const updateSessionRegistration = function (id, status, token) {
  let promise = axios.post(baseUrl + "updatesessionregistration", {
    sessionregistrationid: id,
    status: status,
    token: token,
  });
  return extractDataPromise(promise);
};

const sessionWaitlist = async function (token) {
  if (process.env.NODE_ENV === "development" && useMock()) {
    const mock = await import("./mocks/sessionWaitlist.json");
    return mock.default;
  }

  let promise = axios.get(baseUrl + "sessionwaitlist", {
    params: {
      t: token,
    },
  });
  return extractDataPromise(promise);
};

const checkTicketCode = async function (code) {
  if (process.env.NODE_ENV === "development" && useMock()) {
    const mock = await import("./mocks/validatelink.json");
    return { data: { value: mock.value } };
  }

  let promise = axios.get(baseUrl + "validatelink", {
    params: {
      code,
    },
  });
  return extractValueFirstPromise(promise);
};

const fetchTicktDetails = async function (token, code) {
  let promise = axios.get(baseUrl + "verifyticketpermit", {
    params: {
      t: token,
      code,
    },
  });
  return extractDataPromise(promise);
};

const checkoutTickets = function (token, data) {
  let promise = axios.post(baseUrl + "createticket", data, {
    params: {
      t: token,
    },
  });
  return extractDataPromise(promise);
};

const createPaymentIntent = function (token, data) {
  let promise = axios.post(baseUrl + "initpayment", data, {
    params: {
      t: token,
    },
  });
  return extractDataPromise(promise);
};

const ticketLinks = function () {
  let promise = axios.get(baseUrl + "ticketlinks");
  return extractDataPromise(promise);
};

export default {
  checkTicketCode,
  ticketLinks,
  createUser,
  checkUserMail,
  generateContactJWT,
  fetchContactData,
  fetchTicktDetails,
  updateContact,
  checkoutTickets,
  createPaymentIntent,
  fetchSessionRegistrations,
  updateSessionRegistration,
  sessionWaitlist,
};
