import { createApp, devtools } from "vue";
import { isAxiosError } from "axios";
import jwt_decode from "jwt-decode";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import locales from "./locales";
import * as Sentry from "@sentry/vue";

import "./assets/sass/general.scss";
import { linkedInInsights, utils } from "./utils";

store.dispatch("init");
store.commit("tokenJWT", sessionStorage.getItem("jwt"));

const app = createApp(App);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    app,
    dsn: "https://f3c2bb883bc761d73462d9b706df4bfc@o4507486829215744.ingest.de.sentry.io/4507491618324560",
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: handleSentryBeforeSend,
  });
}

app.use(devtools);
app.use(store);
app.use(router);
app.use(locales);
app.use(utils);

app.mount("#app");

let currentHeight = 0;
const parentWindow = window.parent;

const LINKEDIN_KEY = "consent:linkedin";
const { consent } = JSON.parse(localStorage.getItem(LINKEDIN_KEY) ?? "{}");
linkedInInsights(consent);

function parseCookies(str) {
  let kv = str.split(";").map((v) => v.split("=").map((val) => val.trim()));
  return new Map(kv);
}

const cookies = parseCookies(document.cookie);
const klaroCookie = cookies.get("klaro-cookie");

if (klaroCookie) {
  try {
    const value = JSON.parse(decodeURIComponent(klaroCookie));
    linkedInInsights(value.linkedin);
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
  }
}

window.addEventListener("storage", (event) => {
  if (event.key !== LINKEDIN_KEY) {
    return;
  }

  const { consent } = JSON.parse(event.newValue ?? "{}");
  linkedInInsights(consent);
});

// dispatch content height in regular intervals
if (parentWindow) {
  window.addEventListener("message", (message) => {
    if (message.data.type === "consent") {
      localStorage.setItem(
        `consent:${message.data.payload.service.name}`,
        JSON.stringify(message.data.payload)
      );
    }
  });

  parentWindow.postMessage({ type: "GET_CONSENT" }, "*");

  setInterval(() => {
    let docHeight = document.body.clientHeight + 150; // just add some safety pixels to reduce the risk of additional scrollbars

    // only update height if it changes
    if (docHeight !== currentHeight) {
      currentHeight = docHeight;
      const messageBody = { ticketshopHeight: currentHeight };
      parentWindow.postMessage(messageBody, "*");
    }
  }, 100);
}

window.debugStore = () => {
  console.log(store.state);
};

window.debugToken = () => {
  const token = sessionStorage.getItem("jwt");

  if (token === null) {
    console.log({ token });
    return;
  }

  const value = jwt_decode(token);
  console.log({ token: value });
};

function handleSentryBeforeSend(event, hint) {
  addAxiosContextRecursive(event, hint?.originalException);
}

function addAxiosContextRecursive(event, error) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error);
  } else if (error instanceof Error && error.cause) {
    addAxiosContextRecursive(event, error.cause);
  }
}

function addAxiosContext(event, error) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
}
