import { loadStripe } from "@stripe/stripe-js";

const devKey = "pk_test_oc1X4vOAoWwGywHzx9JbEgdc00so2R5RXg";
const prodKey = "pk_live_VSkG8hvaKCtqQAqG1yVe3Qrf007LexkoZG";

let striptInstance = null;

export function initStripe() {
  let key = process.env.NODE_ENV === "production" ? prodKey : devKey;
  return loadStripe(key).then((stripe) => {
    striptInstance = stripe;
    return stripe;
  });
}

export function getStripe() {
  return striptInstance;
}
