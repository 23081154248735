<template>
  <div class="cv-loading">
    <div class="cv-loading-bounce1"></div>
    <div class="cv-loading-bounce2"></div>
    <div class="cv-loading-bounce3"></div>
  </div>
</template>

<style lang="scss">
.cv-loading {
  margin: 0 auto 0;
  width: 30;
  text-align: center;
  opacity: 0.5;

  & > div {
    width: 8px;
    height: 8px;
    margin: 0 1px;
    background-color: currentColor;
    border-radius: 100%;
    display: inline-block;
    animation: cv-loading-loading-fx 1.4s infinite ease-in-out both;
  }
  .cv-loading-bounce1 {
    animation-delay: -0.32s;
  }
  .cv-loading-bounce2 {
    animation-delay: -0.16s;
  }
}

@keyframes cv-loading-loading-fx {
  0%,
  80%,
  100% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1);
  }
}
</style>
