<template>
  <div class="cv-field cv-field-input">
    <input
      :type="type"
      :required="required"
      :value="modelValue"
      :placeholder="placeholder"
      :pattern="pattern"
      @input="onChanged"
      @blur="onBlur"
      :id="id"
    />
    <label
      class="cv-field__label"
      :for="id"
      v-if="label"
    >
      {{ label }}<template v-if="required"> <span aria-hidden="true"> *</span></template>
    </label>
  </div>
</template>

<script>
import { reportValidity, uuid } from "../../utils";

export default {
  data() {
    return {
      blurred: false,
      dirty: false,
      hasValidatedOnBlur: false,
      id: null,
    }
  },
  props: {
    modelValue: {
      type: [String, Number],
    },
    label: String,
    pattern: String,
    placeholder: String,
    title: String,
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onChanged(e) {
      this.$emit("update:modelValue", e.currentTarget.value);
      this.dirty = true;
      if (this.blurred) {
        reportValidity(e.target);
      }
    },

    onBlur(e) {
      this.blurred = true;

      if (this.dirty && !this.hasValidatedOnBlur) {
        reportValidity(e.target);
        this.hasValidatedOnBlur = true;
      }
    }
  },
  beforeMount() {
    this.$store.dispatch("getUserDataOfLocalStorage", "registerMail");
    this.$store.dispatch("getUserDataOfLocalStorage", "registerFirstName");
    this.$store.dispatch("getUserDataOfLocalStorage", "registerLastName");
  },
  mounted() {
    this.id = uuid();

    if (this.title === "email") {
      this.$emit("update:modelValue", this.$store.getters.registerMail);
    }
    if (this.title === "firstname") {
      this.$emit("update:modelValue", this.$store.getters.registerFirstName);
    }
    if (this.title === "lastname") {
      this.$emit("update:modelValue", this.$store.getters.registerLastName);
    }
  },
};
</script>

<style lang="scss">
.cv-field-input {
  input {
    @extend %basic-field;
    font-family: "degular";
  }
  input::placeholder {
    color: lightgrey;
  }
}
input[pattern]:invalid {
  color: red;
}
input[type="date"] {
  display: block;

  /* Solution 1 */
  /* -webkit-appearance: textfield;*/
  /*-moz-appearance: textfield;*/
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Solution 2 */
  min-width: 96%;
}
</style>
