// Sets an item with a Key to local storage
const saveStorage = function (key, data) {
  localStorage.setItem(key, JSON.stringify(data));
};

// Looks for a local storage item and returns if present
const getStorage = async function (key, item) {
  const itemSet = localStorage.getItem(key) !== null;
  if (itemSet && item) {
    const data = JSON.parse(localStorage.getItem(key));
    return data[item];
  } else if (itemSet) {
    if (localStorage.getItem(key) !== "undefined") {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  } else {
    return null;
  }
};

// Clear a single item or the whole local storage
const clearStorage = function (key = "false") {
  if (key) {
    localStorage.removeItem(key);
  } else {
    localStorage.clear();
  }
};
// Exports all avaliable functions on the script
export { getStorage, saveStorage, clearStorage };
