<template>
  <div class="cv-tokenredeemed">
    <div class="cv-text--intro cv-margin">
      <p class="cv-style--error">
        {{ $t("ticket_already_redeemed") }}
      </p>
    </div>
    <cv-ticket-permit-info class="cv-margin" />
    <!-- <cv-infobox class="cv-margin">
      <div class="cv-style--bold">
        {{ $t("ticket_already_got_confirmationmail") }}
      </div>
      <div>
        {{ $t("ticket_mail_contains_confirmationcode") }}
        {{ $t("ticket_mail_resend_confirmationcode") }}
      </div>
    </cv-infobox> -->
    <!-- <div class="cv-formbox">
      <cv-form class="cv-form--singleline" @submit="submitData">
        <div class="cv-headline">{{ $t("resend_email") }}:</div>
        <cv-input-field
          v-model="mail"
          type="email"
          :required="true"
          :label="$t('user_mail')"
        />
      </cv-form>
    </div> -->
  </div>
</template>

<script>
// import CvForm from "../components/form/Form.vue";
// import CvInputField from "../components/form/InputField.vue";
import CvTicketPermitInfo from "../components/TicketPermitInfo.vue";
//import CvInfobox from "../components/Infobox.vue";

export default {
  components: {
    // CvForm,
    // CvInputField,
    CvTicketPermitInfo,
    //CvInfobox,
  },
  data: () => ({
    mail: "",
  }),
  methods: {
    submitData() {
      //TODO funktion implementieren
      alert("API Call in dem eine neue E-Mail gesendet wird.");
      //this.$store.dispatch("confirmMail", this.mail);
    },
  },
};
</script>

<style lang="scss"></style>
